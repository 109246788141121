import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Custom Material Module
import { CustomMaterialModule } from './material';
import { NavComponent } from './nav/nav.component';
import { DialogComponent } from './dialog/dialog.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { MenuComponent } from './menu/menu.component';
import { ContactComponent } from './contact/contact.component';
import { FourZeroFourComponent } from './four-zero-four/four-zero-four.component';
import { TermsComponent } from './terms/terms.component';
import { FooterComponent } from './footer/footer.component';
import { CateringComponent } from './catering/catering.component';

@NgModule({
    declarations: [
        AppComponent,
        NavComponent,
        DialogComponent,
        HomeComponent,
        AboutComponent,
        MenuComponent,
        ContactComponent,
        FourZeroFourComponent,
        TermsComponent,
        FooterComponent,
        CateringComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CustomMaterialModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
