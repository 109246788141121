<div class="jumbotron_header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <img src="/assets/img/logos/logo-slogan.png"/>
                <h1>Contact Us</h1>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_contact">
    <div class="container">
        <div class="row">
            <div class="jumbotron_contact_delivery col-lg-7 col-md-6 col-sm-12 col-12">
                <h1>Delivery</h1>
                <h2>(517) 482-2326 or (517) 482-2413</h2>
                <p>Minimum Amount - $25.00 <br>Deliveries are only available to downtown Lansing.</p>
                <p>Breakfast deliveries must be placed before 10:10 a.m. Lunch deliveries must be placed before 11:00 a.m., if you would like to receive the order before 12:00 p.m. Time space for deliveries is limited, so call early.</p>
                <p>Delivery charges are as follows:</p>
                <p>
                    Orders of $25 - $50 = $7.00 <br>
                    Orders of $50 - $75 = $10.00 <br>
                    Orders of $75 - $100 = $14.00 <br>
                    Orders of $100 & Over = $18.00 <br>
                </p>
            </div>
            <div class="jumbotron_contact_info col-lg-5 col-md-6 col-sm-12 col-12">
                <h1>Contact</h1>
                <h2><i class="fas fa-map-pin"></i>Jalapenos Mexican Restaurant</h2>
                <p>307 S. Washington Square</p>
                <p>Lansing, Michigan 48933</p>
                <br>
                <a href="tel:15174822326"><p><i class="fas fa-phone"></i>(517) 482-2326</p></a>
                <br>
                <p><i class="fas fa-clock"></i>Mon - Fri: 8 AM - 2 PM</p>
                <p>CLOSED Saturdays & Sundays</p>
            </div>
        </div>
    </div>
</div>
