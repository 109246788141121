<div class="jumbotron_header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <img src="/assets/img/logos/logo-slogan.png"/>
                <h1>Menu</h1>
                <a class="menu-buttons" target="_blank" href="/assets/pdf/jalapenos_menu.pdf">View Printable PDF Menu</a>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_menu">
    <img class="burrito" src="/assets/img/photos/burrito.png">
    <img class="lime" src="/assets/img/photos/lime.png">
    <img class="tomatoes" src="/assets/img/photos/tomatoes.png">
    <img class="chips" src="/assets/img/photos/tortilla-chips.png">
    <img class="margarita" src="/assets/img/photos/margarita.png">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a class="menu-buttons" routerLink="/menu" fragment="breakfast">Breakfast</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="soups-ensaladas">Soups & Ensaladas</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="tacos-tostadas">Tacos & Tostadas</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="burritos">Burritos</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="quesadillas">Quesadillas</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="mambo-combos">Mambo Combos</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="stuffed-jalapenos">Stuffed Jalapenos</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="nachos">Nachos</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="sides">Sides</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="salads">Salads</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="algo-dulce">Algo Dulce</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="beverages">Beverages</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="taco-kits">Taco Kits</a>
                <p class="menu-buttons">&#8226;</p>
                <a class="menu-buttons" routerLink="/menu" fragment="lent-specials">Lent Specials</a>
                <p class="menu-buttons">&#8226;</p>
            </div>
        </div>
        <div class="row" id="breakfast">
            <div class="col-12">
                <h1>Breakfast</h1>
                <hr>
                <p style="color: var(--darkgray);">Served: 8:00 AM - 12:00 PM Mon - Fri</p>
                <p>* All Burritos & Tacos Include: Eggs, Hash Browns & Cheese</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <h2>Burritos</h2>
                <p>(12" Flour Tortilla)</p>
                <ul>
                    <li>
                        <h3>Vegetarian</h3>
                        <h4>$7.00</h4>
                    </li>
                    <li>
                        <h3>Ham</h3>
                        <h4>$7.50</h4>
                    </li>
                    <li>
                        <h3>Bacon</h3>
                        <h4>$7.50</h4>
                    </li>
                    <li>
                        <h3>Sausage</h3>
                        <h4>$7.50</h4>
                    </li>
                    <li>
                        <h3>Chorizo</h3><h3 style="color:var(--lightgray);">(Mexican Sausage)</h3>
                        <h4>$7.50</h4>
                    </li>
                    <h3 style="color:var(--lightgray);">*Naked Burrito - No Tortilla</h3>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <h2>Tacos</h2>
                <p>(6" Flour Tortilla)</p>
                <ul>
                    <li>
                        <h3>Vegetarian</h3>
                        <h4>$5.00</h4>
                    </li>
                    <li>
                        <h3>Ham</h3>
                        <h4>$5.50</h4>
                    </li>
                    <li>
                        <h3>Bacon</h3>
                        <h4>$5.50</h4>
                    </li>
                    <li>
                        <h3>Sausage</h3>
                        <h4>$5.50</h4>
                    </li>
                    <li>
                        <h3>Chorizo</h3><h3 style="color:var(--lightgray);">(Mexican Sausage)</h3>
                        <h4>$5.50</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Additional Items</h2>
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Rice</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Beans</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Onions</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Tomato</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Lettuce</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Cheese</h3>
                        <h4>$1.50</h4>
                    </li>
                    <li>
                        <h3>Green Peppers</h3>
                        <h4>$0.30</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Mushrooms</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Black Olives</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Jalapenos</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Guacamole</h3>
                        <h4>Market Price</h4>
                    </li>
                    <li>
                        <h3>Pico de Gallo</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Sour Cream</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Extra Meat</h3>
                        <h4>$ 2.00</h4>
                    </li>
                    <li>
                        <h3>Rice & Beans Platter</h3>
                        <h4>$ 2.59</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Wraps (12")</h2>
                <p>Try your Burrito on a flavored wrap</p>
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Cheddar/Jalapeno</h3>
                        <h4>$1.00</h4>
                    </li>
                    <li>
                        <h3>Tomato</h3>
                        <h4>$1.00</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Spinach</h3>
                        <h4>$1.00</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br>
                <h2>Breakfast Nachos!</h2>
                <p style="color: var(--darkgray);">Served: 8:00 AM - 12:00 PM Mon - Thu</p>
                <br>
                <p>A mountain of chips smothered in a salsa mixture, topped with egg, potato and cheese with your choice of meat and four (4) Toppings</p>
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>1/2 Order</h3>
                        <h4>$11.29</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Full Order</h3>
                        <h4>$12.69</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <br>
                <h3>MEAT:</h3>
                <br><br>
                <h6>Ham</h6>
                <h6>Bacon</h6>
                <h6>Sausage</h6>
                <h6>Chorizo</h6>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <br>
                <h3>Toppings:</h3>
                <br><br>
                <ul>
                    <li>
                        <h3>Onions</h3>
                    </li>
                    <li>
                        <h3>Tomatoes</h3>
                    </li>
                    <li>
                        <h3>Lettuce</h3>
                    </li>
                    <li>
                        <h3>Green Peppers</h3>
                    </li>
                    <li>
                        <h3>Mushrooms</h3>
                    </li>
                    <li>
                        <h3>Black Olives</h3>
                    </li>
                    <li>
                        <h3>Jalapenos</h3>
                    </li>
                    <li>
                        <h3>Sour Cream</h3>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" id="soups-ensaladas">
            <div class="col-12">
                <h1>Sopas & Ensaladas</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <h3>1. Southwest Chili</h3>
                <h4>$5.49</h4>
                <h5>12 oz. of our special recipe chili, served with chips and cheese.</h5>
                <h3>2. * Chicken Tortilla</h3>
                <h4>$5.49</h4>
                <h5>served with cheese, sour cream and tortilla straws.</h5>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <h3>3. Santa Fe Salad</h3>
                <h4>$9.89 / $11.29</h4>
                <h5>We smash corn chips, then pile “Hi” lettuce, tomato, our secret fiesta sauce, cheese, then top it off with guacamole, sour cream, and black olives. (Our secret Fiesta sauce contains meat.) <br> YOUR CHOICE: Shredded Beef, Chicken or Ground Beef Also available Big Bowl Style $0.75 extra</h5>
            </div>
        </div>
        <div class="row" id="tacos-tostadas">
            <div class="col-12">
                <h1>Tacos & Tostadas</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <h3>4. Hard Shell Taco</h3>
                <h4>$3.69</h4>
                <h5>Corn tortilla, , your choice of shredded beef, ground beef or chicken, tomato, lettuce, cheese.</h5>
                <h3>5. Soft Taco</h3>
                <h4>$3.69</h4>
                <h5>A double corn tortilla, your choice of shredded beef, ground beef or chicken, tomato, lettuce, cheese.</h5>
                <h3>6. Soft Flour Taco</h3>
                <h4>$3.69</h4>
                <h5>Flour tortilla, , your choice of shredded beef, ground beef or chicken, tomato, lettuce and cheese.</h5>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <h3>7. Tostada</h3>
                <h4>$4.09</h4>
                <h5>A flat taco, beans, lettuce, tomato and cheese.</h5>
                <h3>8. Tostada Grande</h3>
                <h4>$4.79</h4>
                <h5>A flat taco, beans, , your choice of shredded beef, ground beef or chicken, tomato, lettuce, cheese.</h5>
            </div>
        </div>
        <div class="row" id="burritos">
            <div class="col-12">
                <h1>Burritos</h1>
                <hr>
                <p>Build your own mess!!</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>9. Burritos</h3>
                <h4>$5.89</h4>
                <h5>Choose one meat and that will get you started. Then combine with as many toppings as your taste Buds will allow! <br> (You can add rice and beans platter for $2.79)</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <h3>MEAT:</h3>
                <br><br>
                <h6>Ground Beef</h6>
                <h6>Shredded Beef</h6>
                <h6>Chicken</h6>
                <h6>Colorado Pork</h6>
                <br>
                <h3>WRAPS:</h3>
                <br><br>
                <ul>
                    <li>
                        <h3 style="color: var(--lightgray);">Cheddar/Jalapeno</h3>
                        <h4>$1.00</h4>
                    </li>
                    <li>
                        <h3 style="color: var(--lightgray);">Tomato</h3>
                        <h4>$1.00</h4>
                    </li>
                    <li>
                        <h3 style="color: var(--lightgray);">Spinach</h3>
                        <h4>$1.00</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Rice</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Beans</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Onions</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Tomato</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Lettuce</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Cheese</h3>
                        <h4>$1.50</h4>
                    </li>
                    <li>
                        <h3>Green Peppers</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Mushrooms</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Black Olives</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Jalapenos</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Guacamole</h3>
                        <h4>Market Price</h4>
                    </li>
                    <li>
                        <h3>Pico de Gallo</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Sour Cream</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Extra Meat</h3>
                        <h4>$ 2.00</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <ul>
                    <li>
                        <h3>10. Rootin-Tootin (Bean & Cheese)</h3>
                        <h4>$5.69</h4>
                        <h5 style="margin-bottom: 0px;">Add any topping from #9 for additional cost.</h5>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" id="quesadillas">
            <div class="col-12">
                <h1>Quesadillas</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>11. Quesadillas</h3>
                <h5>A large flour tortilla loaded with cheese and pico de gallo (onions, tomatoes, cilantro, jalapenos, salt and vinegar) and grilled to perfection. <br> (Add rice and bean platter for $2.59)</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Chicken Fajita</h3>
                        <h4>$9.29</h4>
                    </li>
                    <li>
                        <h3>Seafood Crab/Shrimp</h3>
                        <h4>$9.29</h4>
                    </li>
                    <li>
                        <h3>Shredded Beef</h3>
                        <h4>$9.29</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Ground Beef</h3>
                        <h4>$9.29</h4>
                    </li>
                    <li>
                        <h3>Shredded Chicken</h3>
                        <h4>$9.29</h4>
                    </li>
                    <li>
                        <h3>Vegetarian</h3>
                        <h4>$8.29</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" id="mambo-combos">
            <div class="col-12">
                <h1>Mambo Combos</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>12. Our Famous Wet Burrito</h3> 
                <h5>Pull out the umbrella for this! A large flour tortilla, stuffed with beans, beef, lettuce, tomato and cheese - drenched in a down pour of secret sauce (contains beans & meat) then baked with cheese.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Ground Beef Solo</h3>
                        <h4>$10.69</h4>
                    </li>
                    <li>
                        <h3>+ Rice & Beans</h3>
                        <h4>$12.39</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Shredded Beef or Chicken Solo</h3>
                        <h4>$10.69</h4>
                    </li>
                    <li>
                        <h3>+ Rice & Beans</h3>
                        <h4>$12.39</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <h3>13. Los Tres Amigos</h3>
                <h5>One Taco (Ground beef, lettuce, tomato, cheese, soft corn). One Tostada (Beans, tomato, lettuce, cheese). One Burrito (Chicken, lettuce, tomato, cheese).</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Served Solo</h3>
                        <h4>$10.69</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>+ Rice & Beans</h3>
                        <h4>$12.39</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <h3>14. Enchilada Amor</h3>
                <h5>3 corn tortillas, each stuffed with ground beef, onion, tomato, then smothered with secret sauce and steamed with cheese.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Ground Beef Solo</h3>
                        <h4>$10.69</h4>
                    </li>
                    <li>
                        <h3>+ Rice & Beans</h3>
                        <h4>$12.39</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Shredded Beef or Chicken Solo</h3>
                        <h4>$10.69</h4>
                    </li>
                    <li>
                        <h3>+ Rice & Beans</h3>
                        <h4>$12.39</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <h3>15. Rice Bowls</h3>
                <h5>Rice, beans, lettuce, cheese, pico de gallo, choice of meat & topped with tortilla straws.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Ground Beef</h3>
                        <h4>$10.69</h4>
                    </li>
                    <li>
                        <h3>Shredded Chicken</h3>
                        <h4>$10.69</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Shredded Beef</h3>
                        <h4>$10.69</h4>
                    </li>
                    <li>
                        <h3>Chicken Fajita</h3>
                        <h4>$11.19</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <h3>16. Chicken Tender Wrap</h3>
                <h5>We take crispy chicken tenders, load it with lettuce, cheese and homemade honey mustard dressing and serve it on a tomato flavored wrap. (tortilla)</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Burrito Only</h3>
                        <h4>$9.42</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>+ Rice & Beans</h3>
                        <h4>$11.19</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" id="stuffed-jalapenos">
            <div class="col-12">
                <h1>Stuffed Jalapenos</h1>
                <hr>
                <p>(All deep fried to order, allow extra time for these items)</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>17a Jalapeno Poppers</h3>
                        <h4>Solo - $9.29</h4>                   
                    </li>
                </ul>
                <h5>A.) Cheddar cheese, mounded high on a half pepper and covered with a delicate potato breading-they'll pop your taste buds. (6 in an order.)</h5>
                <ul>
                    <li>
                        <h3>17b Jalapeno Poppers</h3>
                        <h4>Solo - $9.29</h4>                   
                    </li>
                </ul>
                <h5>B.) Fire Roasted Jalapenos stuffed with cream cheese - They're hot! </h5>
                <p>(w/ Rice and Beans platter $10.79)</p>
            </div>
        </div>
        <div class="row" id="nachos">
            <div class="col-12">
                <h1>Nachos</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>18. Chips and Cheese</h3>
                <h5>A mountain of chips, smothered with cheese. We have two versions: #1 Spicy Cheese Sauce (dip) or #2 Grated Cheddar Cheese that we bake. (Please specify)</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>1/2 Order</h3>
                        <h4>$9.39</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Full Order</h3>
                        <h4>$10.59</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <h3>19. Macho Nacho</h3>
                <h5>Muscle through this! Chips, beans, ground beef, onion, tomato, jalapenos, topped with lettuce, cheese & sour cream. YOUR CHOICE of Ground Beef, Chicken, or Shredded Beef</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>1/2 Order</h3>
                        <h4>$11.29</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Full Order</h3>
                        <h4>$12.69</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <br><br>
                        <h3>20. El Plato Loco</h3>
                        <h4>$11.29</h4>                   
                    </li>
                </ul>
                <h5>The Crazy Plate! Beans, choice of meat, onions, tomatoes, cheese, lettuce, sour cream and Jalapenos. Dive into this with a bag of our homemade chips. Same as Macho Nacho but chips are on the side.</h5>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>21. Chicken Fajita Taco</h3>
                        <h4>$4.69</h4>                   
                    </li>
                </ul>
                <h5>Soft flour shell, pico de gallo (contains onions, tomatoes, vinegar, cilantro, and spices), lettuce and cheese.</h5>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12">
                <h3>22. Chicken Fajita Burrito</h3>
                <h5>Flour tortilla, pico de gallo (contains onions, tomatoes, vinegar, cilantro, and spices), lettuce and cheese.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Served Solo</h3>
                        <h4>$8.29</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>+ Rice and Beans</h3>
                        <h4>$10.29</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <h3>23. Chimi-Chimi Bang-Bang!</h3>
                <h5>Large flour tortilla filled with beans, ground beef, tomato, cheese - deep fried golden brown, topped with lettuce and cheese sauce.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Ground Beef</h3>
                        <h4>$10.59</h4>
                    </li>
                    <li>
                        <h3>+ Rice and Beans</h3>
                        <h4>$12.29</h4>
                    </li>
                    <br>
                    <li>
                        <h3>Shredded Beef</h3>
                        <h4>$10.59</h4>
                    </li>
                    <li>
                        <h3>+ Rice and Beans</h3>
                        <h4>$12.29</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Chicken</h3>
                        <h4>$10.59</h4>
                    </li>
                    <li>
                        <h3>+ Rice and Beans</h3>
                        <h4>$12.29</h4>
                    </li>
                    <br>
                    <li>
                        <h3>Seafood Crab/Shrimp</h3>
                        <h4>$11.29</h4>
                    </li>
                    <li>
                        <h3>+ Rice and Beans</h3>
                        <h4>$12.99</h4>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <br><br>
                        <h3>25. Texas Toothpicks</h3>
                        <h4>$5.19</h4>                   
                    </li>
                </ul>
                <h5>Thin cut strips of onions and Jalapenos coated with a crisp zesty batter.</h5>
            </div>
        </div> -->
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>26. Flautas </h3>
                        <h4>$11.99</h4>                   
                    </li>
                </ul>
                <h5>3 corn tortillas filled with shredded beef or chicken (please specify) fried golden crisp, served with guacamole, sour cream, lettuce, cheese, pico de gallo and our rice and bean platter.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>27. Chicken Chipotle Spring Rolls</h3>
                        <h4>$10.69</h4>                   
                    </li>
                </ul>
                <h5>Chicken Chipotle & cheese flour tortilla fried golden crisp, stuffed with chicken, chipotle peppers & cheese. Served with rice and beans.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3>28. Pork Carnitas </h3>
                <h5>In a double corn tortilla stuffed with pork, onion, cilantro. Served with a wedge of lime.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>2 Tacos - Solo</h3>
                        <h4>$8.09</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>+ Rice and Beans</h3>
                        <h4>$9.99</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" id="sides">
            <div class="col-12">
                <h1>On the Side</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Salsa</h3>
                        <h4>$3.39 / $5.69</h4>
                    </li>
                    <li>
                        <h3>Refried Beans</h3>
                        <h4>$2.89 / $3.39</h4>
                    </li>
                    <li>
                        <h3>Spanish Rice</h3>
                        <h4>$2.89 / $3.39</h4>
                    </li>
                    <li>
                        <h3>Queso Dip</h3>
                        <h4>$1.50 / $5.00 / $8.00</h4>
                    </li>
                    <li>
                        <h3>Bag of Chips</h3>
                        <h4>$2.11 / $3.39</h4>
                    </li>
                    <li>
                        <h3>1 lb. 6oz. of Chips</h3>
                        <h4>$9.89</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Sour Cream</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Jalapenos</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Extra Cheese (any item)</h3>
                        <h4>$1.50</h4>
                    </li>
                    <li>
                        <h3>Extra Meat (or side of meat)</h3>
                        <h4>$2.00</h4>
                    </li>
                    <li>
                        <h3>Bambinos</h3>
                        <h4>$1.99</h4>
                    </li>
                    <li>
                        <h3>Guacamole</h3>
                        <h4>Market Price</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <p>(1 Salsa per item any additional $.25)</p>
            </div>
        </div>
        <div class="row" id="salads">
            <div class="col-12">
                <h1>Salads</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>Tossed</h3>
                        <h4>$3.39 / $5.39</h4>                   
                    </li>
                </ul>
                <h5>Lettuce, Tomato, Cheese.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>Bacon Ranch</h3>
                        <h4>$5.99 / $9.09</h4>                   
                    </li>
                </ul>
                <h5>Lettuce, Tomato, Bacon, Egg, Cheese.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>Crispy Chicken Cobb</h3>
                        <h4>$6.59 / $10.99</h4>                   
                    </li>
                </ul>
                <h5>Lettuce, Tomato, Chicken, Egg, Bacon, Blue Cheese.</h5>
                <p>All Salads come with croutons and choice of dressing</p>
            </div>
        </div>
        <div class="row" id="algo-dulce">
            <div class="col-12">
                <h1>Algo Dulce</h1>
                <hr>
                <p>DESSERTS!</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>Churros</h3>
                        <h4>$3.49</h4>                   
                    </li>
                </ul>
                <h5>2 lightly fried vanilla flavored cinnamon sticks.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>Bambinos</h3>
                        <h4>$1.99</h4>                   
                    </li>
                </ul>
                <h5>Peach, Strawberry, Apple, Cherry, or Blueberry.</h5>
            </div>
        </div>
        <!--
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>Fried Ice Cream</h3>
                        <h4>$2.99</h4>                   
                    </li>
                </ul>
                <h5>Vanilla Ice Cream Rolled in a crispy shell! Excellent! (Add Hot Fudge $3.49)</h5>
            </div>
        </div>
        -->
        <div class="row">
            <div class="col-12">
                <h3>Soft Serve Ice Cream</h3>
                <h5>Only served during Summer months. Frost bites & cones and a variety of toppings!</h5>
            </div>
        </div>
        <div class="row" id="beverages">
            <div class="col-12">
                <h1>Beverages</h1>
                <hr>
                <p>Coke - Diet Coke - Diet Sprite - Mello Yello - Orange Minute Maid - Unsweetened Fuze</p>
                <br><br>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>16 oz.</h3>
                        <h4>$2.39</h4>
                    </li>
                    <li>
                        <h3>20 oz.</h3>
                        <h4>$2.59</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>32 oz.</h3>
                        <h4>$2.79</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <p>We carry Coca-Cola products in 20 oz. bottles. OJ, Minute Maid Juices</p>
                <!-- <p>We carry Coca-Cola products in 20 oz. bottles. OJ, Minute Maid Juices $2.19</p> -->
                <br><br>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <br><br>
                <p>* Prices subject to change without notice. Revised 6/23</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-specials" id="taco-kits">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>To-Go Taco Kits</h1>
                <hr>
                <h2>Step 1: Choose the best kit for you!</h2>
                <p>4 tacos: $13</p>
                <p>8 tacos: $25</p>
                <p>12 tacos: $37</p>
                <h2>Step 2: Choose one protein ($1 up charge for 2)</h2>
                <p>Ground Beef</p>
                <p>Shredded Beef</p>
                <p>Shredded Chicken</p>
                <p>Pork Carnita</p>
                <p>Colorado Pork</p>
                <h2>Step 3: Choose one type of tortilla</h2>
                <p>Flour</p>
                <p>Crunchy Corn</p>
                <p>Soft Corn (doubled of course)</p>
                <p>Tostada</p>
                <h2>Step 4: Choose one type of Salsa</h2>
                <p>Mild</p>
                <p>Hot</p>
                <h2>All kits come with a free extra large of homemade chips and there three toppings</h2>
                <p>Tomato</p>
                <p>Lettuce</p>
                <p>Cheese</p>
                <h2>Additional items are always available!</h2>
                <p>Onions</p>
                <p>Cilantro</p>
                <p>Pico de Gallo</p>
                <p>Salsa</p>
                <p>Jalapenos</p>
                <p>Black olives</p>
                <p>gaucamole</p>
                <p>sour cream</p>
                <p>beans</p>
                <p>rice</p>
                <p>queso</p>
                <p>chips</p>
                <p>bambinos</p>
                <p>churros</p>
                <p>tortillas</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_menu" id="lent-specials">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="row" id="nachos">
                    <div class="col-12">
                        <h1 style="margin-top: 0px;">Lent Specials</h1>
                        <hr>
                        <!-- <p>Only available during yearly during Lent</p> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h3>Shrimp Tacos</h3>
                        <h5>Sauteed garlic shrimp, served with fresh cilantro. lettuce and lime sauce. Have it our way or make it your own.</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                        <ul>
                            <li>
                                <h3>Half Order</h3>
                                <h4>$9.99</h4>
                            </li>
                        </ul>  
                    </div>
                    <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                        <ul>
                            <li>
                                <h3>Full Order</h3>
                                <h4>$10.99</h4>
                            </li>
                        </ul>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <h3>Shrimp Nachos</h3>
                        <h5>Sauteed garlic shrimp, served with cheese, pico de gallo, sour cream and lime sauce on the side.</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                        <ul>
                            <li>
                                <h3>Half Order</h3>
                                <h4>$11.49</h4>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                        <ul>
                            <li>
                                <h3>Full Order</h3>
                                <h4>$12.99</h4>
                            </li>
                        </ul>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <h3>Shrimp Quesadillas</h3>
                        <h5>Sauteed garlic shrimp, cheese, pico da gallo, lettuce and lime sauce. Have it our way or make it your own.</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                        <ul>
                            <li>
                                <h3>Half Order</h3>
                                <h4>$11.09</h4>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                        <ul>
                            <li>
                                <h3>Full Order</h3>
                                <h4>$12.59</h4>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <br><br>
                        <p>* You can also get any of our menu items without meat!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_ordering">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h1>Online Ordering</h1>
                <a target="_blank" href="https://www.clover.com/online-ordering/jalapenos-lansing"><i class="fas fa-shopping-cart"></i>Order Now</a>
                <p>(Pick-Up ONLY)</p>
            </div>
        </div>
    </div>
</div>
