import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  appTitle = "Jalapeno's Mexican Restaurant";
  year: number = new Date().getFullYear();

  todaysHours: string;

  toTopButton() {
    window.scroll(0, 0);
  }
  
  constructor() {
  }

  ngOnInit() {
    // TO TOP BUTTON
    window.onscroll = function() {
        scrollFunction()
    };
    function scrollFunction() {
        if (document.body.scrollTop > 850 || document.documentElement.scrollTop > 850) {
            document.getElementById("ToTopButton").style.display = "block";
        } else {
            document.getElementById("ToTopButton").style.display = "none";
        }
    }
  }

  hours() {
    const date = new Date();
    const day = date.getDay();
    // if (day === 0) {
    //   this.todaysHours = 'Today: CLOSED';
    // }
    // if (day === 1) {
    //   this.todaysHours = 'Today: CLOSED';
    // }
    // if (day === 2) {
    //   this.todaysHours = 'Today: CLOSED';
    // }
    // if (day === 3) {
    //   this.todaysHours = 'Today: 8:00AM - 2:00PM';
    // }
    // if (day === 4) {
    //   this.todaysHours = 'Today: 8:00AM - 2:00PM';
    // }
    // if (day === 5) {
    //   this.todaysHours = 'Today: 8:00AM - 2:00PM';
    // }
    // if (day === 6) {
    //   this.todaysHours = 'Today: CLOSED';
    // }
    if (day === 0) {
      this.todaysHours = 'Today: CLOSED';
    }
    if (day === 1) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 2) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 3) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 4) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 5) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 6) {
      this.todaysHours = 'Today: CLOSED';
    }
  }

}