<div class="jumbotron_header">
    <img class="lime" src="/assets/img/photos/lime.png">
    <img class="burrito" src="/assets/img/photos/burrito.png">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <img class="logo" src="/assets/img/logos/logo-slogan.png"/>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <h1>Downtown Lansing's Mexican restaurant.</h1>
                <p>Everything is hand-made with fresh ingredients. It's like being in Mexico for lunch!</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_specials">
    <img class="tomatoes" src="/assets/img/photos/tomatoes.png">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Weekly Specials</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fas fa-calendar-day"></i>
                <h2>Monday, Tuesday & Friday Specials</h2>
                <!-- <p>Returning Soon!</p> -->
                <p>2 Flour Tacos w/rice & beans with your choice of Shredded Beef, Ground Beef, or Chicken. <b>$9.89</b></p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fas fa-calendar-day"></i>
                <h2>Wednesday Special</h2>
                <!-- <p>Returning Soon!</p> -->
                <p>A Burrito with meat, rice & beans, tomato and cheese - on the inside with your choice of Shredded Beef, Ground Beef, or Chicken. Served w/rice and beans on the side. <b>$10.89</b></p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fas fa-calendar-day"></i>
                <h2>Thursday Special</h2>
                <!-- <p>Returning Soon!</p>            -->
                <p>1 Taco, 1 Tostada Served w/rice & beans on the side with your choice of Shredded Beef, Ground Beef, or Chicken. <b>$10.89</b></p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_menu">
    <img class="chips" src="/assets/img/photos/tortilla-chips.png">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Menu Preview</h1>
                <hr>
                <h2>Breakfast</h2>
                <p style="color: var(--darkgray);">Served: 8:00 AM - 12:00 PM M-F</p>
                <p>* All Burritos & Tacos Include: Eggs, Hash Browns & Cheese</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <h2>Burritos</h2>
                <p>(12" Flour Tortilla)</p>
                <ul>
                    <li>
                        <h3>Vegetarian</h3>
                        <h4>$7.00</h4>
                    </li>
                    <li>
                        <h3>Ham</h3>
                        <h4>$7.50</h4>
                    </li>
                    <li>
                        <h3>Bacon</h3>
                        <h4>$7.50</h4>
                    </li>
                    <li>
                        <h3>Sausage</h3>
                        <h4>$7.50</h4>
                    </li>
                    <li>
                        <h3>Chorizo</h3><h3 style="color:var(--lightgray);">(Mexican Sausage)</h3>
                        <h4>$7.50</h4>
                    </li>
                    <h3 style="color:var(--lightgray);">*Naked Burrito - No Tortilla</h3>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <h2>Tacos</h2>
                <p>(6" Flour Tortilla)</p>
                <ul>
                    <li>
                        <h3>Vegetarian</h3>
                        <h4>$5.00</h4>
                    </li>
                    <li>
                        <h3>Ham</h3>
                        <h4>$5.50</h4>
                    </li>
                    <li>
                        <h3>Bacon</h3>
                        <h4>$5.50</h4>
                    </li>
                    <li>
                        <h3>Sausage</h3>
                        <h4>$5.50</h4>
                    </li>
                    <li>
                        <h3>Chorizo</h3><h3 style="color:var(--lightgray);">(Mexican Sausage)</h3>
                        <h4>$5.50</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Additional Items</h2>
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Rice</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Beans</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Onions</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Tomato</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Lettuce</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Cheese</h3>
                        <h4>$1.50</h4>
                    </li>
                    <li>
                        <h3>Green Peppers</h3>
                        <h4>$0.30</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Mushrooms</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Black Olives</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Jalapenos</h3>
                        <h4>$0.30</h4>
                    </li>
                    <li>
                        <h3>Guacamole</h3>
                        <h4>Market Price</h4>
                    </li>
                    <li>
                        <h3>Pico de Gallo</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Sour Cream</h3>
                        <h4>$0.50</h4>
                    </li>
                    <li>
                        <h3>Extra Meat</h3>
                        <h4>$ 2.00</h4>
                    </li>
                    <li>
                        <h3>Rice & Beans Platter</h3>
                        <h4>$ 2.59</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Wraps (12")</h2>
                <p>Try your Burrito on a flavored wrap</p>
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Cheddar/Jalapeno</h3>
                        <h4>$1.00</h4>
                    </li>
                    <li>
                        <h3>Tomato</h3>
                        <h4>$1.00</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Spinach</h3>
                        <h4>$1.00</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a routerLink="/menu">View Full Menu</a>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_gift_card">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                    <img src="/assets/img/photos/jalapenos-gift-card.png">
                </div>
                <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    <h1>Gift Cards</h1>
                    <p>Give a Gift! Come in and purchase a gift card(s) for any occasion.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_info">
    <div class="container">
        <div class="row">
            <div class="ordering col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <i class="fas fa-shopping-bag"></i>
                <h1>Delivery</h1>
                <h2>(517) 482-2326 or (517) 482-2413</h2>
                <p>Minimum Amount - $25.00 <br>Deliveries are only available to downtown Lansing.</p>
                <p>Breakfast deliveries must be placed before 10:10 a.m. Lunch deliveries must be placed before 11:00 a.m., if you would like to receive the order before 12:00 p.m. Time space for deliveries is limited, so call early.</p>
                <p>Delivery charges are as follows:</p>
                <p>
                    Orders of $25 - $50 = $7.00 <br>
                    Orders of $50 - $75 = $10.00 <br>
                    Orders of $75 - $100 = $14.00 <br>
                    Orders of $100 & Over = $18.00 <br>
                </p>
            </div>
            <div class="hours col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <i class="far fa-clock"></i>
                <h1>Hours</h1>
                <p [ngClass]="activeSun()">Sunday: Closed</p>
                <p [ngClass]="activeMon()">Monday: 8AM - 2PM</p>
                <p [ngClass]="activeTues()">Tuesday: 8AM - 2PM</p>
                <p [ngClass]="activeWed()">Wednesday: 8AM - 2PM</p>
                <p [ngClass]="activeThurs()">Thursday: 8AM - 2PM</p>
                <p [ngClass]="activeFri()">Friday: 8AM - 2PM</p>
                <p [ngClass]="activeSat()">Saturday: Closed</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_ordering">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h1>Online Ordering</h1>
                <a target="_blank" href="https://www.clover.com/online-ordering/jalapenos-lansing"><i class="fas fa-shopping-cart"></i>Order Now</a>
                <p>(Pick-Up ONLY)</p>
            </div>
        </div>
    </div>
</div>
