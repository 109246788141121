import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-four-zero-four',
  templateUrl: './four-zero-four.component.html',
  styleUrls: ['./four-zero-four.component.scss']
})
export class FourZeroFourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}