<div class="jumbotron-dialog">
    <div class="container">
        <div class="row">
            <div class="top col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/logos/logo-slogan.png">
            </div>
            <!-- <div class="bottom col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Christmas Holiday Break</h1>
                <h2>Closed Dec. 19th - Jan. 6th</h2>
                <p>Jalapenos Mexican Restaurant will be closed the week of Monday December 19th - Friday January 6th.</p>
                <p>Sorry for any inconveniences and see you <b style="color: var(--lightestdarkgray)">Monday January 9th, 2023!</b></p>
                <button routerLink="/menu" fragment="lent-specials" [mat-dialog-close]>Lent Specials</button>
            </div> -->
            <div class="bottom col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/lent_specials.jpg" alt="lent specials">
                <button routerLink="/menu" fragment="lent-specials" [mat-dialog-close]>View Menu</button>
                <h6>Now through April 6th</h6>
            </div>
        </div>
    </div>
</div>