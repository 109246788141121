import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  appTitle = 'Jalapenos';
  panelOpenState = false;
  todaysHours: string;

  todaysDate: any | undefined;
  startDate: any | undefined;
  endDate: any | undefined;

  viewNotification = false;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getNotificationStatus();
  }

  getNotificationStatus(): void {
    this.startDate = new Date('02/22/2023');
    this.endDate = new Date('03/13/2023');
    // GET TODAY'S DATE
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    this.todaysDate = new Date(mm + '/' + dd + '/' + yyyy);

    if (this.todaysDate > this.startDate && this.todaysDate <= this.endDate) {
      // console.log('true');
      this.viewNotification = true;
      this.openDialog();
    } else {
      // console.log('false');
      this.viewNotification = false;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  hours() {
    const date = new Date();
    const day = date.getDay();
    if (day === 0) {
      this.todaysHours = 'Today: CLOSED';
    }
    if (day === 1) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 2) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 3) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 4) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 5) {
      this.todaysHours = 'Today: 8:00AM - 2:00PM';
    }
    if (day === 6) {
      this.todaysHours = 'Today: CLOSED';
    }
  }

  toggleSideNav() {
    const x = document.getElementById('sideNav');

    if (x.style.display === 'block') {
      x.style.display = 'none';
    } else {
      x.style.display = 'block';
    }
  }

}
