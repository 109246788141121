import { NgModule } from '@angular/core';

// Anuglar Animations
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        MatToolbarModule,
        MatDialogModule,
    ],
    exports: [
        BrowserAnimationsModule,
        MatToolbarModule,
        MatDialogModule,
    ],
})
export class CustomMaterialModule { }
