<div class="jumbotron_header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <img src="/assets/img/logos/logo-slogan.png"/>
                <h1>404 Error</h1>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_content">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <img src="../assets/img/logos/404.png">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>We can't find the page you're looking for. You can either return to the home page or contact our support team.</p>
                <button class="primary" routerLink="/">Visit Homepage</button>
                <button class="secondary" routerLink="/contact">Contact Us</button>
            </div>
        </div>
    </div>
</div>