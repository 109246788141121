import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // Display Active Hours in Footer Info
  activeSun() {
    const date = new Date();
    const day = date.getDay();
    if (day === 0) {
      return 'active';
    }
  }
  activeMon() {
    const date = new Date();
    const day = date.getDay();
    if (day === 1) {
      return 'active';
    }
  }
  activeTues() {
    const date = new Date();
    const day = date.getDay();
    if (day === 2) {
      return 'active';
    }
  }
  activeWed() {
    const date = new Date();
    const day = date.getDay();
    if (day === 3) {
      return 'active';
    }
  }
  activeThurs() {
    const date = new Date();
    const day = date.getDay();
    if (day === 4) {
      return 'active';
    }
  }
  activeFri() {
    const date = new Date();
    const day = date.getDay();
    if (day === 5) {
      return 'active';
    }
  }
  activeSat() {
    const date = new Date();
    const day = date.getDay();
    if (day === 6) {
      return 'active';
    }
  }

  constructor() { }

  ngOnInit() {
  }
}
