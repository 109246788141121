<div class="jumbotron_header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <img src="/assets/img/logos/logo-slogan.png"/>
                <h1>About Us</h1>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_about">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-1 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12">
                <p>Downtown Lansing's Mexican restaurant. Everything is handmade with fresh ingredients. It's like being in Mexico for lunch!</p>
            </div>
            <div class="col-lg-2 col-md-1 hidden-sm hidden-xs">
            </div>
        </div>
    </div>
</div>
