<div class="navbar-header">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <mat-toolbar>
          <mat-toolbar-row>
            <span class="spacer"></span>
              <a target="_blank" href="https://www.google.com/maps/dir//Jalape%C3%B1os+Mexican+Restaurant,+South+Washington+Square,+Lansing,+MI/@42.7309237,-84.5868837,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8822c1d9bd074f15:0x1848f2ec57cb7a93!2m2!1d-84.5518643!2d42.730929"><i class="fas fa-map-marker-alt"></i>307 S Washington Square, Lansing, MI 48933</a>
              <a href="tel:15174822326"><i class="fas fa-phone"></i>(517) 482-2326</a>
              <p [ngClass]="hours()"><i class="fas fa-clock"></i>{{ todaysHours }}</p>
              <a *ngIf="viewNotification === true" (click)="openDialog()"><i class="fas fa-bell"></i>View Notification</a>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </div>
  </div>
</div>


<div class="navbar-desktop" id="navbar-desktop">
  <div class="container">
    <mat-toolbar>
      <mat-toolbar-row>
        <img src="/assets/img/logos/logo.png">
        <h1>Mexican<br>Restaurant</h1>
        <span class="spacer"></span>
        <button routerLink="/" [routerLinkActive]="'navbar-desktop-active'" [routerLinkActiveOptions]="{ exact: true }">Home</button>
        <!-- <button routerLink="/about" [routerLinkActive]="'navbar-desktop-active'">About</button> -->
        <button routerLink="/menu" [routerLinkActive]="'navbar-desktop-active'">Menu</button>
        <button routerLink="/catering" [routerLinkActive]="'navbar-desktop-active'">Catering</button>
        <button routerLink="/contact" [routerLinkActive]="'navbar-desktop-active'">Contact</button>
        <a target="_blank" href="https://www.clover.com/online-ordering/jalapenos-lansing">
          <!-- <i class="fas fa-shopping-cart"></i> -->
          Order Online <br> <b style="font-size: 8px;">(Pick-Up ONLY)</b>
        </a>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</div>
  
<div class="navbar-mobile">
  <div class="container-fluid">
    <div class="row">
      <img src="/assets/img/logos/logo.png">
      <h1>Mexican<br>Restaurant</h1>
      <button class="button" (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
    </div>
  </div>
</div>

<div id="sideNav">
  <div class="container-fluid">
    <div class="row">
      <button class="button" (click)="toggleSideNav()"><i class="material-icons">close</i></button>
    </div>
    <div class="row">
      <div class="side-nav-content">
          <img src="/assets/img/logos/logo-slogan.png">
          <button routerLink="/" (click)="toggleSideNav()" [routerLinkActive]="'navbar-mobile-active'" [routerLinkActiveOptions]="{ exact: true }">Home</button>
          <!-- <button routerLink="/about" (click)="toggleSideNav()" [routerLinkActive]="'navbar-mobile-active'">About</button> -->
          <button routerLink="/menu" (click)="toggleSideNav()" [routerLinkActive]="'navbar-mobile-active'">Menu</button>
          <button routerLink="/catering" (click)="toggleSideNav()" [routerLinkActive]="'navbar-mobile-active'">Catering</button>
          <button routerLink="/contact" (click)="toggleSideNav()" [routerLinkActive]="'navbar-mobile-active'">Contact</button>
          <a class="button-order" target="_blank" href="https://www.clover.com/online-ordering/jalapenos-lansing">
            <!-- <i class="fas fa-shopping-cart"></i> -->
            Order Online <br> <b style="font-size: 8px;">(Pick-Up ONLY)</b>
          </a>
          <!-- <a class="button-dialog" (click)="openDialog()" style="margin-left: 20px;">View Notification</a> -->
      </div>
    </div>
  </div>
</div>