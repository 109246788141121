<a (click)="toTopButton()" id="ToTopButton"><i class="fa fa-angle-up"></i></a>

<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2930.7146994500513!2d-84.5540529845319!3d42.73092897916374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8822c1d9bd074f15%3A0x1848f2ec57cb7a93!2sJalape%C3%B1os+Mexican+Restaurant!5e0!3m2!1sen!2sus!4v1554823395225!5m2!1sen!2sus" width="100%" height="300" frameborder="0" style="border:0" allowfullscreen></iframe>

<div class="jumbotron_info">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div style="display: block; margin: 0px auto 0px auto;">
                    <h1>JALAPENOS MEXICAN RESTAURANT</h1>
                    <a target="_blank" href="https://www.google.com/maps/place/Jalape%C3%B1os+Mexican+Restaurant/@42.730929,-84.554053,17z/data=!3m1!4b1!4m5!3m4!1s0x8822c1d9bd074f15:0x1848f2ec57cb7a93!8m2!3d42.730929!4d-84.5518643"><i class="fas fa-map-marker-alt"></i>307 S Washington Square, Lansing, MI 48933</a>
                    <a href="tel:15174822326"><i class="fas fa-phone"></i>(517) 482-2326</a>
                    <p [ngClass]="hours()"><i class="fas fa-clock"></i>{{ todaysHours }}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p>© {{ appTitle }}, {{ year }}. All Rights Reserved. | <a href="/terms"> Terms</a></p>
                <p>Design by: © <a href="https://www.thegreenedgellc.com" target="_blank"  style="color: var(--thegreenedge);">The Green Edge LLC</a></p> 
            </div>
        </div>
    </div>
</div>